import classNames from 'classnames';
import React from 'react';
import { AmrFile } from '../../../../types/amr-pipeline/models/AmrFile';
import { OnHoverTooltip, Preloader } from '../../../common';

interface DownloadDocumentProps {
    document?: AmrFile;
    children: React.ReactNode;
    secondary?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    loadingText?: boolean;
    tooltipOverlay?: string;
}

export default function DownloadDocument({
    document,
    children,
    secondary = false,
    isLoading,
    loadingText,
    tooltipOverlay,
    onClick,
    disabled,
}: DownloadDocumentProps) {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (onClick && !disabled && !isLoading) {
            return onClick();
        }
    };

    return (
        <>
            <OnHoverTooltip disabled={disabled} overlay={isLoading ? undefined : tooltipOverlay ?? document?.name}>
                <a
                    onClick={handleClick}
                    href="/"
                    className={classNames('btn-link text-ellipsis btn-download-file', {
                        secondary: secondary,
                        disabled,
                        'btn-is-loading': isLoading,
                    })}
                >
                    {isLoading ? (
                        <Preloader text={loadingText ? 'Downloading...' : ''} inProgress small fullScreen={false} />
                    ) : (
                        children
                    )}
                </a>
            </OnHoverTooltip>
        </>
    );
}
