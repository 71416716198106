import DownloadDocument from "./DownloadDocument";
import { IColumnDefinition } from "../../../bidding/common/table/types/ColumnDefinition";
import { ColumnBuilder } from "../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import FileIcon from "../../../controls/FileIcon";
import { constants, SORTING_TYPE } from "../../../../constants";
import moment from "moment";
import IconSVG from '../../../../styles/svg-icons';
import { FieldDifferenceLabel } from '../../detailed/new-issue/DifferenceLabel';
import { OriginatingTransactionDocument } from '../../../../types/amr-pipeline/models/OriginatingTransactionDocument';

interface FileWithLastDate extends OriginatingTransactionDocument {
    lastDate: Date
}

const documentName: IColumnDefinition<FileWithLastDate> = {
    columnKey: "name",
    renderColumnHeaderContent: () => "Name",
    headerClassName: "data-list-cell-xl-flexible",
    bodyClassName: "data-list-cell-xl-flexible",
    renderColumnContent: (document, { dealReferenceName, isRemoved, handleClick }) => (
        <DownloadDocument
            document={document}
            onClick={() => handleClick(document)}
        >
            {isRemoved ? (
                <IconSVG name="basket" width="16" height="16" />
            ) : (
                <FileIcon filename={document.name} />
            )}
            {document.name}
        </DownloadDocument>
    ),
    sortingField: "name",
    sortingType: SORTING_TYPE.string,
};

const downloadButton: IColumnDefinition<FileWithLastDate> = {
    columnKey: "downloadButton",
    renderColumnHeaderContent: () => "",
    headerClassName: "data-list-cell-xxs-02",
    bodyClassName: "data-list-cell-xxs-02",
    renderColumnContent: (document, { handleClick, isLoading }) => (
        <DownloadDocument
            document={document}
            isLoading={isLoading}
            onClick={() => handleClick(document)}
        >
            <IconSVG name="downloadTemplate" width={16} height={16} />
        </DownloadDocument>
    ),
};

const uploadedDate: IColumnDefinition<FileWithLastDate> = {
    columnKey: "lastDate",
    renderColumnHeaderContent: () => "Date",
    headerClassName: "data-list-cell-sm",
    bodyClassName: "data-list-cell-sm",
    renderColumnContent: (document) => {
        const lastDate = document.executionDate || document.uploadTime;
        return moment(lastDate).format(constants.dateFormat);
    },
    sortingField: "lastDate",
    sortingType: SORTING_TYPE.date
};

const differenceLabel: IColumnDefinition<FileWithLastDate> = {
    columnKey: "difference",
    renderColumnHeaderContent: () => "",
    headerClassName: "data-list-cell-sm",
    bodyClassName: "data-list-cell-sm",
    renderColumnContent: (document, { difference }) => (
        <FieldDifferenceLabel difference={difference} />
    ),
};

export const dealDocumentsColumns = () => {
    const documentsColumnDefinitions: IColumnDefinition<FileWithLastDate>[] = [
        documentName,
        differenceLabel,
        uploadedDate,
        downloadButton
    ];
    return documentsColumnDefinitions.map((c) => {
        return new ColumnBuilder(c);
    });
};
