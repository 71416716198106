import { AmrDocument } from '../../../../types/amr-pipeline/models/AmrDocument';
import { OriginatingTransactionDocument } from '../../../../types/amr-pipeline/models/OriginatingTransactionDocument';
import { Table } from '../../../bidding/common/table';
import { dealDocumentsColumns } from './documents-columns';
import { AmrFile } from '../../../../types/amr-pipeline/models/AmrFile';
import { DifferenceType, ObjectArrayDifference, ObjectArrayItemDifference } from '../../../../utils/differ/types';
import { DifferenceListProps, withDeletedItems } from '../../detailed/new-issue/withDeletedItems';
import { DocumentGroup } from './DocumentGroup';
import { DownloadFile } from '../../../../types/amr-pipeline/models/DownloadedDocument';
import { Nullable } from '../../../../types/primitives/Nullable';
import useDownloadDocumentList from '../../../../effects/useDownloadDocumentList';
import { amrPipelineService } from '../../../../services/amr-pipeline.service';
import { dealsService } from '../../../../services/deals.service';

type TransactionDocument = AmrDocument | OriginatingTransactionDocument;

interface DocumentGroupProps<TArgs extends any[]>
    extends DifferenceListProps<TransactionDocument, TransactionDocument> {
    title: string;
    dealReferenceName: string;
    transactionReferenceName?: string;
    items: TransactionDocument[];
    difference?: ObjectArrayDifference<TransactionDocument[]>;
    downloadAllFetch: (...args: TArgs) => Promise<DownloadFile>;
    downloadAllArgs: TArgs;
    fileNameForSave: Nullable<string>;
    emptyPlaceholder?: string;
}

function isRemoved(documentDifference: ObjectArrayItemDifference<TransactionDocument> | undefined) {
    return documentDifference?.type === DifferenceType.Removed;
}

function Component<TArgs extends any[]>({
    dealReferenceName,
    transactionReferenceName,
    title,
    items,
    difference,
    emptyPlaceholder,
    downloadAllFetch,
    downloadAllArgs,
    fileNameForSave,
}: DocumentGroupProps<TArgs>) {
    const { loadingState, loadHandler } = useDownloadDocumentList({
        documents: items,
        downloadRequest:transactionReferenceName
                    ? (referenceName) => amrPipelineService.getTransactionDocument(dealReferenceName, transactionReferenceName, referenceName)
                    : (referenceName: string) => dealsService.getDealDocument(dealReferenceName, referenceName),
        dealReferenceName,
        transactionReferenceName,
    });

    async function onRowClick(document: AmrFile) {
        const documentDifference = getDifference(document.referenceName);

        if (isRemoved(documentDifference)) {
            return null;
        }

        loadHandler(document.referenceName, document.name);
    }

    function getDifference(referenceName: string) {
        // Find array item difference by item reference name
        // Default currentValue with previousValue for deleted documents
        return difference?.find(({ derivedValue }) => derivedValue?.referenceName === referenceName);
    }

    return (
        <DocumentGroup
            title={title}
            itemsCount={items.length}
            emptyPlaceholder={emptyPlaceholder}
            downloadAllFetch={downloadAllFetch}
            downloadAllArgs={downloadAllArgs}
            fileNameForSave={fileNameForSave}
        >
            <Table
                className="component-file-upload-list data-list-striped"
                dataItems={items}
                onRowClick={onRowClick}
                columns={dealDocumentsColumns()}
                createSecurityCustomArgs={(item: AmrDocument | OriginatingTransactionDocument) => {
                    const itemDifference = getDifference(item.referenceName);
                    const isDocRemoved = isRemoved(itemDifference);

                    return {
                        dealReferenceName,
                        handleClick: isDocRemoved ? () => null : onRowClick,
                        difference: itemDifference,
                        isRemoved: isDocRemoved,
                        isLoading: loadingState[item.referenceName],
                    };
                }}
                createSecurityCustomClassName={(item: AmrDocument | OriginatingTransactionDocument) => {
                    const itemDifference = getDifference(item.referenceName);
                    if (isRemoved(itemDifference)) {
                        //NOTE FOR MARKUP DEVS. Please, create class for disabled document rows
                        return 'disabled';
                    }
                }}
            />
        </DocumentGroup>
    );
}

export const DocumentSection = withDeletedItems(Component);
